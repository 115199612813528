.header {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  background-color: var(--bg-color);
  padding: 1rem 2.5rem;
  z-index: 100;
}
.header__logo {
  width: 4.5rem;
}
.header__logo:hover {
  cursor: pointer;
}
.header__navbar {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
@media (max-width: 768px) {
  .header__navbar {
    position: absolute;
    display: flex;
    left: -100%;
    top: 0;
    width: 65vw;
    height: 100vh;
    visibility: hidden;
    background-color: var(--accent-color);
    transition: all var(--animation-speed) ease;
    z-index: 20;
  }
  .header__navbar.active-nav {
    left: 0;
    visibility: visible;
  }
}
.header__nav {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  transition: all var(--animation-speed) ease;
}
@media (max-width: 768px) {
  .header__nav {
    margin: auto;
    flex-direction: column;
  }
}
.header__nav-item {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 768.1px) {
  .header__nav-item:last-child {
    margin-left: 1.875rem;
  }
}
.header__nav-link {
  font-size: 1.5rem;
  transition: all var(--animation-speed) ease;
}
.header__nav-link:hover {
  color: var(--accent-color);
}
@media (max-width: 768px) {
  .header__nav-link {
    color: var(--bg-color);
  }
  .header__nav-link:hover {
    color: var(--text-color);
  }
}
.header__subnav {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  visibility: hidden;
  opacity: 0;
  top: 50px;
  transition: all var(--animation-speed) ease;
}
.header__subnav::before {
  content: "";
  position: absolute;
  display: block;
  margin: 0 auto;
  left: -0.5rem;
  top: -0.5rem;
  background-color: var(--accent-color);
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  width: 100%;
  height: 100%;
  z-index: -10;
}
.header__subnav.show-langs {
  visibility: visible;
  opacity: 1;
}
.header__subnav-item {
  cursor: pointer;
  color: var(--bg-color);
  transition: all var(--animation-speed) ease;
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.header__subnav-item:hover {
  color: var(--text-color);
}

.burger-menu {
  display: none;
}
@media (max-width: 768px) {
  .burger-menu {
    display: block;
    position: relative;
    width: 2.8125rem;
    height: 1.6875rem;
    cursor: pointer;
    z-index: 100;
  }
  .burger-menu span {
    top: calc(50% - 0.094rem);
    left: 0;
    position: absolute;
    width: 100%;
    height: 0.1875rem;
    background-color: var(--accent-color);
    transition: all var(--animation-speed) ease;
  }
  .burger-menu span:first-child {
    top: 0;
  }
  .burger-menu span:last-child {
    top: auto;
    bottom: 0;
  }
  .burger-menu.burger-active span {
    transform: scale(0);
  }
  .burger-menu.burger-active span:first-child {
    transform: rotate(135deg);
    top: calc(50% - 1px);
  }
  .burger-menu.burger-active span:last-child {
    transform: rotate(45deg);
    bottom: calc(50% - 1px);
  }
}

.language-switcher {
  cursor: pointer;
}