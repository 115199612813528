.tech {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.125rem;
}
@media (max-width: 768px) {
  .tech {
    flex-direction: column-reverse;
  }
}
.tech__list {
  display: flex;
  gap: 1.25rem;
  border: 0.125rem solid var(--accent-color);
  border-radius: 1.25rem;
  padding: 1.25rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
@media (max-width: 768px) {
  .tech__list {
    flex-direction: column;
  }
}
.tech__block {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  list-style: disc;
}
.tech__item {
  text-align: left;
  margin: 0 20px;
}
.tech__article {
  max-width: 550px;
  text-align: right;
}
.tech__text {
  font-size: clamp(1rem, 0.887rem + 0.57vw, 1.4rem);
}