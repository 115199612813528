.intro {
  display: flex;
  gap: clamp(3.75rem, 2.304rem + 7.23vw, 7.5rem);
  align-items: center;
  justify-content: space-between;
  margin-top: 150px;
}
@media (max-width: 768px) {
  .intro {
    flex-direction: column;
    justify-content: center;
  }
}
.intro__article {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
@media (max-width: 768px) {
  .intro__article {
    align-items: flex-end;
    text-align: end;
  }
}
.intro__subtitle {
  font-size: 1rem;
}
.intro__name {
  font-size: clamp(3rem, 2.229rem + 3.86vw, 5rem);
}
.intro__title {
  font-size: clamp(1.5rem, 1.075rem + 2.12vw, 3rem);
}
.intro__text code, .intro__text strong {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  font-weight: bold;
  color: var(--accent-color);
  cursor: pointer;
  text-decoration: underline;
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
  text-underline-position: under;
}
.intro__picBox {
  height: clamp(12.5rem, 6.476rem + 30.12vw, 28.125rem);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}
.intro__picBox:hover .intro__picOutline {
  transform: translate(-5%, -5%);
  background-color: var(--accent-color);
}
@media (max-width: 768px) {
  .intro__picBox {
    display: none;
  }
}
.intro__codePic {
  position: relative;
  height: 100%;
  z-index: 20;
}
.intro__picOutline {
  position: absolute;
  top: 3.125rem;
  left: 3.125rem;
  border: 0.094rem solid var(--accent-color);
  height: 100%;
  width: 100%;
  transition: all var(--animation-speed) ease-in;
  opacity: 0.4;
}