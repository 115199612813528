@font-face {
  font-family: "Comic Sans MS";
  src: url("/src/assets/fonts/comic_sans_ms-webfont.woff2") format("woff2"), url("/src/assets/fonts/comic_sans_ms-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
:root {
  --text-color: #8892B0;
  --text2-color: #CCD6F6;
  --bg-color: #0A192F;
  --accent-color: #64ffda;
  --animation-speed: 0.5s;
  --font-sans: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
  --font-mono: "Fira Code", "Fira Mono", "Roboto Mono", monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::-moz-selection {
  background-color: var(--accent-color);
  color: var(--bg-color);
}
*::selection {
  background-color: var(--accent-color);
  color: var(--bg-color);
}

body {
  background-color: var(--bg-color);
  position: relative;
}

html {
  width: clamp(20rem, 100vw, 90.625rem);
  margin: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
  scrollbar-width: none;
}
html::-webkit-scrollbar {
  width: 0;
}

h1, h2, h3, h4, h5, h6, p, li {
  cursor: default;
}

ul, ol {
  list-style: none;
}

a {
  text-decoration: none;
}

p, a, li {
  color: var(--text-color);
}

#preload {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("/public/images/preload.svg");
  background-color: var(--bg-color);
  display: block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1000;
  transition: all var(--animation-speed) ease-in;
}

.title {
  color: var(--text-color);
  font-family: var(--font-mono);
  font-size: clamp(1.6rem, 0.867rem + 3.66vw, 3.5rem);
}

.subtitle {
  color: var(--accent-color);
  font-family: var(--font-sans);
}

.text {
  color: var(--text2-color);
  font-family: var(--font-sans);
}

code, strong, time {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
}