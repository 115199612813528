.about {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.25rem;
}
.about__article {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (max-width: 768px) {
  .about__article {
    align-items: flex-end;
    text-align: end;
  }
}
.about__text {
  font-size: clamp(1rem, 0.887rem + 0.57vw, 1.4rem);
}
.about__text::first-letter {
  font-size: clamp(1.6rem, 1.253rem + 1.73vw, 2.5rem);
  color: var(--accent-color);
}
.about__text code, .about__text strong {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
  font-weight: bold;
  color: var(--accent-color);
  text-decoration: underline;
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
  text-underline-position: under;
}
.about__pic {
  width: clamp(18.75rem, 16.095rem + 13.27vw, 28.125rem);
}
@media (max-width: 768px) {
  .about__pic {
    display: none;
  }
}